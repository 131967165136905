(function () {
  'use strict';

  class ResourceCreateCtrl {
    constructor(Resources, Media, $mdToast, $document, Utils, $scope, $timeout, $stateParams, Discussions, Polls, Modules) {
      let vm = this,
          background = new Image(),
          scale_factor = null,
          newWidth = null,
          newHeight = null,
          x = null,
          y = null;
      vm.ctrlName = 'ResourceCtrl';
      vm.Resources = Resources;
      vm.Media = Media;
      vm.Polls = Polls;
      vm.$timeout = $timeout;
      vm.$stateParams = $stateParams;
      vm.Modules = Modules;
      vm.Utils = Utils;
      vm.Discussions = Discussions;
      vm.$document = $document;
      vm.$mdToast = $mdToast;
      vm.types = Utils.resourceTypes();
      vm.canvas = vm.$document[0].getElementById('canvas');
      vm.ctx = vm.canvas.getContext('2d');
      background.onload = function () {
        vm.clear();
        // let w = vm.canvas.width;
        // nw = background.naturalWidth;
        // nh = background.naturalHeight;
        // aspect = nw / nh;
        // h = w / aspect;
        // console.log('height', h);
        // vm.ctx.drawImage(background, 0, 0, w, vm.canvas.height);
        scale_factor = Math.min(vm.canvas.width / background.width, vm.canvas.height / background.height);
        newWidth = background.width * scale_factor;
        newHeight = background.height * scale_factor;
        x = vm.canvas.width / 2 - newWidth / 2;
        y = vm.canvas.height / 2 - newHeight / 2;
        vm.ctx.drawImage(background, x, y, newWidth, newHeight);
        // vm.Utils.drawImageProp(vm.ctx, background);
      };
      $scope.$watch('resourceCreate.mediaCanvas', () => {
        if (vm.mediaCanvas.file) {
          vm.mediaCanvas.file.convertToBase64((data)=> {
            background.src = data;
            $scope.$apply();
          });
        }
      });
      vm.init();
    }
    init() {
      const vm = this;
      vm.isLoading = false;
      vm.boolTag = false;
      vm.discussionPrompt = [];
      vm.resource = {};
      vm.poll = {
        text: '',
        poll_questions: [{
          text: '',
          question_type: 'bar_chart',
          allowed_answers_number: 1,
          poll_question_options: [{text: ''}]
        },
        {
          text: '',
          question_type: 'pie_chart',
          allowed_answers_number: 2,
          poll_question_options: [{text: ''}, {text: ''}]
        }]
      };
      if (vm.$form) {
        vm.$form.$setPristine();
        vm.$form.$setUntouched();
      }
      if (vm.$stateParams.moduleId) {
        vm.getModule();
      }
    }
    getModule() {
      const vm = this;
      vm.Modules.moduleView({id: vm.$stateParams.moduleId})
        .then((data)=> {
          vm.module = data.module_document;
        });
    }
    createDiscussion() {
      const vm = this;
      vm.discussionPrompt.push({title: ''});
    }
    addAnswer(poll_question_options) {
      poll_question_options.push({text: ''});
    }
    removeAnswer(poll_question_options, index) {
      if (poll_question_options.length > 1) {
        poll_question_options.splice(index, 1);
      }
    }
    uploadMedia(file) {
      const vm = this;
      return vm.Media.mediaCreate(file)
        .then((data)=>{
          return data.media;
        });
    }
    pollCreate(resourceData) {
      const vm = this;
      vm.poll.resource_id = resourceData.resource.id;
      vm.Polls.pollsCreate(vm.poll)
        .then(()=> {
          vm.message();
          vm.clear();
          vm.init();
        });
    }
    postDiscussions(length, resourceData, counter = 0) {
      const vm = this;
      if (vm.discussionPrompt[counter]) {
        vm.Discussions.discussionsCreate(vm.discussionPrompt[counter])
        .then(() => {
          counter++;
          vm.postDiscussions(length, resourceData, counter);
        });
      } else {
        vm.checkResourceType(resourceData);
      }
    }
    checkResourceType(resourceData) {
      const vm = this;
      switch (vm.resource.resource_type) {
        case 'poll':
          vm.pollCreate(resourceData);
          break;
        case 'canvas':
          vm.canvasCreate(resourceData);
          break;
        default:
          vm.clear();
          vm.init();
          vm.message();
      }
    }
    canvasCreate(resourceData) {
      const vm = this,
          canvas = vm.Utils.dataURItoBlob(vm.canvas.toDataURL('image/png'));
      vm.uploadMedia(canvas)
      .then((media) => {
        vm.setupCanvasDiscussion(media, resourceData);
      });
    }
    setupCanvasDiscussion(media, resourceData) {
      const vm = this,
          resource = resourceData.resource;

      resource.media_id = media.id;

      if (vm.mediaLarge.element.value) {
        vm.uploadMedia(vm.mediaLarge.file)
        .then((mediaLarge) => {
          resource.large_canvas_image_id = mediaLarge.id;
          vm.Resources.resourceEdit(resource)
            .then(() => {
              vm.clear();
              vm.init();
              vm.message();
            });
        });
      } else {
        vm.Resources.resourceEdit(resource)
          .then(() => {
            vm.clear();
            vm.init();
            vm.message();
          });
      }
    }
    setupDiscussions(resourceData) {
      const vm = this,
          resource = resourceData.resource;
      angular.forEach(vm.discussionPrompt, function (discussionPrompt, index) {
        discussionPrompt.discussionable_id = resource.id;
        discussionPrompt.order = index++;
        discussionPrompt.discussionable_type = 'Resource';
      });
      vm.postDiscussions(vm.discussionPrompt.length, resourceData);
    }
    resourcesCreate() {
      const vm = this;
      vm.Resources.resourcesCreate(vm.resource)
        .then((resourceData) => {
          vm.setupDiscussions(resourceData);
          if (vm.module) {
            vm.sendToModule(resourceData);
          }
        })
        .catch((response) => {
          const {data: {
            error
          }} = response;
          angular.forEach(error, function (v) {
            vm.message(v.join(' '));
          });
          vm.isLoading = false;
        });
    }
    sendToModule({resource}) {
      const vm = this,
          resourceNodes = vm.module.resource_nodes;
      resourceNodes.push({
        order: resourceNodes.length,
        resource_id: resource.id
      });
      let payload = {
        id: vm.module.id,
        resource_nodes: resourceNodes
      };
      vm.Modules.resourceNodesCreate(payload)
        .then(()=> {
          vm.message('Module Assigned.');
        });
    }
    submitResource(form) {
      const vm = this;
      vm.isLoading = true;
      if (form.$valid) {
        vm.$form = form;
        vm.resourcesCreate();
      } else {
        vm.isLoading = false;
        vm.message('Please make sure that the required fields are filled');
      }
    }
    clear() {
      let vm = this;
      vm.ctx.clearRect(0, 0, 800, 600);
      vm.mediaCanvas.element.value = '';
      vm.mediaLarge.element.value = '';
    }
    removeItem(index) {
      const vm = this;
      vm.discussionPrompt.splice(index, 1);
    }
    message(msg = 'Resource Created Successfully') {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
  }

  /**
   * @ngdoc object
   * @name learning.resource.controller:ResourceCtrl
   *
   * @description
   *
   */
  angular
    .module('learning.resource')
    .controller('ResourceCreateCtrl', ResourceCreateCtrl);
}());
